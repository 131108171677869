<template>
	<BaseLoading
		v-if="isLoading"
		is-full-page
	/>
	<div v-else>
		<div class="text-right">
			<router-link
				:to="{ name: 'PageCreate' }"
				class="link-button"
			>
				<CButton class="btn-create" color="secondary">
					<CIcon class="mr-2" name="cil-plus" />
					Create
				</CButton>
			</router-link>
		</div>
		<CRow>
			<CCol lg="6" class="page-column">
				<PageList
					:ref="pageLevelOneRef"
					:list="pageLevelOneList"
					:is-draggable="!isSorting"
					label="Level 1"
					class="pages-list"
					@onSelect="(id) => handleSelectPage(id, PAGE_LEVEL.ONE)"
					@onDragged="(ids) => handleUpdateOrderPage(ids, pageLevelOneRef)"
				/>
			</CCol>
			<CCol lg="6" class="page-column">
				<PageList
					:ref="pageLevelTwoRef"
					:list="pageLevelTwoList"
					:is-draggable="!isSorting"
					label="Level 2"
					class="pages-list"
					@onSelect="(id) => handleSelectPage(id, PAGE_LEVEL.TWO)"
					@onDragged="(ids) => handleUpdateOrderPage(ids, pageLevelTwoRef)"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import PageList from '@/components/PageList.vue';
import { PAGE_LEVEL } from '../enums/pages';
import { getActiveItemChildren } from '../assets/js/helpers';
import { transformedActive } from '../assets/js/transform/transform';

export default {
	name: 'Pages',
	components: {
		PageList,
	},
	data() {
		return {
			pageLevelOneRef: 'page-list-1',
			pageLevelTwoRef: 'page-list-2',
			PAGE_LEVEL,
		};
	},
	computed: {
		...mapState('pages', {
			list: 'list',
			create: 'create',
			delete: 'delete',
			sort: 'sort',
		}),
		...mapGetters({
			pageList: 'pages/pageList',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		isSorting() {
			return this.sort.isLoading;
		},
		isSortError() {
			return this.sort.error;
		},
		isCreateSuccess() {
			return this.create.isSuccess;
		},

		// Page list
		pageLevelOneList() {
			// Set isActive
			const activeId = this.list.activeIds[PAGE_LEVEL.ONE];
			return this.pageList.map((page) => transformedActive(page, activeId));
		},
		pageLevelTwoList() {
			// Find level two list
			const list = getActiveItemChildren(this.pageLevelOneList);

			// Set isActive
			const activeId = this.list.activeIds[PAGE_LEVEL.TWO];
			return list.map((page) => transformedActive(page, activeId));
		},
	},
	created() {
		this.getPages();
	},
	methods: {
		...mapActions({
			getPages: 'pages/getPages',
			selectPage: 'pages/selectPage',
			updateOrderPages: 'pages/updateOrderPages',
		}),

		handleSelectPage(pageId = null, pageLevel = PAGE_LEVEL.ONE) {
			if (pageId) {
				this.selectPage({ pageId, pageLevel });
			}
		},
		async handleUpdateOrderPage(pageIds = [], ref = null) {
			await this.updateOrderPages(pageIds);

			if (this.isSortError && this.$refs[ref]) {
				// Revert sorting
				this.$refs[ref].handleRevertSorting();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pages {
	// .pages-column
	&-column {
		margin-bottom: rem(32);

		&:last-child {
			margin-bottom: 0;
		}

		@include lg {
			margin-bottom: 0;
		}
	}

	// .pages-list
	&-list {
		height: 100%;
		display: flex;
		flex-direction: column;

		::v-deep .page-list {
			height: 100%;
		}
	}
}

.btn-create {
	margin-bottom: rem(20);
}
</style>
